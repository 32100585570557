<template>
  <div class="fullscreen" id="content">
    <el-card class="box-card login-card">
      <template #header>
        <div class="card-header">
          <span style="color: #8d9095">Login Tao5g scrapy news</span>
        </div>
      </template>
      <el-form :model="form">
        <el-form-item label="username">
          <el-input v-model="form.username" />
        </el-form-item>
        <el-form-item label="password">
          <el-input v-model="form.password" type="password" />
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="onSubmit">Login</el-button>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "Login",
  created() {
    if (localStorage.getItem("user")) {
      this.$router.push("/");
    }
  },
};
</script>

<script setup>
import { reactive } from "vue";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import authApi from "../api/resources/auth.js";

// do not use same name with ref
const form = reactive({
  username: "",
  password: "",
});
const router = useRouter();

const onSubmit = async () => {
  const resp = await authApi.login(form);
  if (resp && resp.code === 1) {
    ElMessage({
      showClose: true,
      message: "You have successfully logged in.",
      type: "success",
    });

    localStorage.setItem("user", JSON.stringify(form));
    localStorage.setItem("token", resp.data.token);

    router.push("/");
  }
};
</script>

<style lang="scss">
html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
//   background-color: #434343;
//   background-image: linear-gradient(#434343, #282828);
}
#content {
  background-color: transparent;
  background-image: linear-gradient(
      0deg,
      transparent 24%,
      rgba(255, 255, 255, 0.05) 25%,
      rgba(255, 255, 255, 0.05) 26%,
      transparent 27%,
      transparent 74%,
      rgba(255, 255, 255, 0.05) 75%,
      rgba(255, 255, 255, 0.05) 76%,
      transparent 77%,
      transparent
    ),
    linear-gradient(
      90deg,
      transparent 24%,
      rgba(255, 255, 255, 0.05) 25%,
      rgba(255, 255, 255, 0.05) 26%,
      transparent 27%,
      transparent 74%,
      rgba(255, 255, 255, 0.05) 75%,
      rgba(255, 255, 255, 0.05) 76%,
      transparent 77%,
      transparent
    );
  height: 100%;
  background-size: 50px 50px;
}

.follow-me {
  position: absolute;
  bottom: 10px;
  right: 10px;
  text-decoration: none;
  color: #ffffff;
}

.fullscreen {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background-color: darken(#252b37, 4%) !important;
}

@mixin backlight($x, $y, $spread, $size, $colorA, $colorB, $duration) {
  &:after {
    position: absolute;
    content: "";
    top: $y;
    left: $x;
    right: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    transform: scale($size);
    -webkit-filter: blur($spread);
    -moz-filter: blur($spread);
    -ms-filter: blur($spread);
    filter: blur($spread);
    background: linear-gradient(270deg, $colorA, $colorB);
    background-size: 200% 200%;
    animation: animateGlow $duration ease infinite;

    @keyframes animateGlow {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }
}

.login-card {
  position: relative;
  text-align: center;
  color: #252b37;
  background-color: #151823;
  @include backlight(0, 50vw, 50vw, 0.75, #0fffc1, #7e0fff, 2s);
}
</style>
