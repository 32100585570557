import { APISettings } from '../config.js';
import { ElMessage } from 'element-plus'

export default {
    
    login(data) {
        return fetch( APISettings.baseURL + '/news/login', {
            method: 'POST',
            headers: {
                'Authorization': 'Basic ' + btoa(data['username'] + ":" + data['password'])
                , ...APISettings.headers}
        } )
        .then( function( response ){
            if( response.status != 201 ){
                ElMessage({
                    showClose: true,
                    message: 'Oops, error username or password.',
                    type: 'error',
                })
                throw response.status;
            }else{
                return response.json();
            }
        });
    }
}